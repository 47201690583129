import React, { useState, useEffect } from 'react';
import { 
  LineChart, XAxis, YAxis, Tooltip, Line, ResponsiveContainer,
  AreaChart, Area
} from 'recharts';
import { 
  LayoutDashboard, 
  Users, 
  Brain, 
  Gamepad,
  BarChart,
  Settings,
  LogOut,
  Menu,
  Bell,
  Search,
  Zap,
  TrendingUp,
  Clock,
  Target,
  Sparkles,
  Mic,
  AlertCircle,
  MessageSquare
} from 'lucide-react';

// Enhanced demo data
const retentionData = [
  { day: 'Day 1', players: 100, predicted: 100 },
  { day: 'Day 2', players: 85, predicted: 82 },
  { day: 'Day 3', players: 72, predicted: 75 },
  { day: 'Day 4', players: 65, predicted: 68 },
  { day: 'Day 5', players: 60, predicted: 62 },
  { day: 'Day 7', players: 55, predicted: 58 },
  { day: 'Day 8', predicted: 54 },
  { day: 'Day 9', predicted: 51 },
  { day: 'Day 10', predicted: 49 },
];

const npcInteractions = [
  { time: '1h', count: 245, engagement: 78 },
  { time: '2h', count: 388, engagement: 85 },
  { time: '3h', count: 465, engagement: 92 },
  { time: '4h', count: 387, engagement: 88 },
  { time: '5h', count: 492, engagement: 95 },
  { time: '6h', count: 564, engagement: 91 },
];

const sentimentData = [
  { time: '1h', positive: 75, neutral: 20, negative: 5 },
  { time: '2h', positive: 68, neutral: 25, negative: 7 },
  { time: '3h', positive: 82, neutral: 15, negative: 3 },
  { time: '4h', positive: 70, neutral: 22, negative: 8 },
  { time: '5h', positive: 78, neutral: 18, negative: 4 },
  { time: '6h', positive: 85, neutral: 12, negative: 3 },
];

const playerBehavior = [
  { category: 'Combat', count: 1240, trend: '+15%' },
  { category: 'Exploration', count: 890, trend: '+8%' },
  { category: 'Questing', count: 650, trend: '+12%' },
  { category: 'Social', count: 450, trend: '+5%' },
  { category: 'Trading', count: 320, trend: '+3%' }
];

const allAiInsights = [
  {
    title: "Player Churn Risk",
    description: "20% of active players show signs of decreased engagement in boss battles. Critical drop in daily logins detected.",
    severity: "high",
    impact: "Revenue & Retention",
  },
  {
    title: "Content Engagement",
    description: "New dungeon area 'Shadow Realm' shows 35% higher engagement. Players spending avg. 45 mins per session.",
    severity: "medium",
    impact: "Player Experience",
  },
  {
    title: "Monetization Opportunity",
    description: "High demand for character customization detected. 75% of active players viewed premium skins.",
    severity: "low",
    impact: "Revenue Growth",
  },
  {
    title: "Matchmaking Alert",
    description: "Peak hour matchmaking times increased by 25%. Server load optimization needed in EU regions.",
    severity: "high",
    impact: "Player Satisfaction",
  },
  {
    title: "Social Feature Impact",
    description: "New guild system driving 40% increase in player interaction. Guild raids particularly successful.",
    severity: "medium",
    impact: "Community Growth",
  },
  {
    title: "Trophy System",
    description: "Trophy completion rate up 28%. PS5 players showing higher engagement with challenges.",
    severity: "low",
    impact: "Platform Integration",
  },
  {
    title: "Resource Economy",
    description: "In-game currency inflation detected. Top tier items becoming too accessible.",
    severity: "high",
    impact: "Game Balance",
  },
  {
    title: "Cross-Play Status",
    description: "PS4 players showing 15% lower win rate against PS5 players in PvP matches.",
    severity: "medium",
    impact: "Fair Play",
  },
  {
    title: "Tutorial Feedback",
    description: "New player tutorial completion rate dropped to 82%. Key dropout point identified.",
    severity: "medium",
    impact: "New Player Experience",
  }
];


const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const hours = new Date().getHours();
  const timeOfDay = hours < 12 ? 'morning' : hours < 17 ? 'afternoon' : 'evening';
  const [currentInsights, setCurrentInsights] = useState(allAiInsights.slice(0, 3));
  const getRandomInsights = () => {
    const shuffled = [...allAiInsights].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, 3);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentInsights(getRandomInsights());
    }, 30000); // Rotate every 30 seconds

    return () => clearInterval(interval);
  }, []);


  // Custom tooltip styles
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 rounded-lg shadow-lg border border-gray-100">
          <p className="font-medium text-sm text-gray-600">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} className="text-sm" style={{ color: entry.color }}>
              {entry.name}: {entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };
  const InsightCard = ({ insight }) => (
    <div className="bg-white p-6 rounded-lg shadow-sm border-l-4 border-indigo-500">
      <div className="flex justify-between items-start mb-2">
        <h4 className="font-medium text-lg">{insight.title}</h4>
        <span className={`px-2 py-1 rounded text-sm ${
          insight.severity === 'high' ? 'bg-red-100 text-red-800' :
          insight.severity === 'medium' ? 'bg-yellow-100 text-yellow-800' :
          'bg-green-100 text-green-800'
        }`}>
          {insight.severity.charAt(0).toUpperCase() + insight.severity.slice(1)}
        </span>
      </div>
      <p className="text-gray-600 mb-2">{insight.description}</p>
      <div className="flex items-center text-sm text-gray-500">
        <Target className="w-4 h-4 mr-1" />
        Impact: {insight.impact}
      </div>
    </div>
  );
  return (
    <div className="h-screen flex bg-gradient-to-br from-gray-50 to-gray-100">
      {/* Sidebar */}
      <div 
        className={`${
          sidebarOpen ? 'w-64' : 'w-20'
        } bg-gradient-to-b from-indigo-700 to-indigo-800 text-white transition-all duration-300 flex flex-col shadow-xl`}
      >
        <div className="p-4 flex items-center justify-between border-b border-indigo-600">
          <h1 className={`font-bold text-xl ${!sidebarOpen && 'hidden'} flex items-center`}>
            <span className="text-2xl mr-2">✨</span>
            VRealityAI
          </h1>
          <button 
            onClick={() => setSidebarOpen(!sidebarOpen)}
            className="p-2 hover:bg-indigo-600 rounded-lg transition-colors duration-200"
          >
            <Menu size={20} />
          </button>
        </div>
        
        <nav className="flex-1 pt-4">
          {[
            { icon: <LayoutDashboard size={20} />, label: 'Dashboard' },
            { icon: <Users size={20} />, label: 'Player Analytics' },
            { icon: <Brain size={20} />, label: 'NPC Behavior' },
            { icon: <Gamepad size={20} />, label: 'Game Adaptation' },
            { icon: <Zap size={20} />, label: 'AI Insights' },
            { icon: <BarChart size={20} />, label: 'Reports' },
            { icon: <Settings size={20} />, label: 'Settings' },
          ].map((item, index) => (
            <button
              key={index}
              className={`w-full flex items-center px-4 py-3 text-gray-300 hover:bg-indigo-600 hover:text-white
                transition-colors duration-200
                ${index === 0 ? 'bg-indigo-800 text-white' : ''}`}
            >
              {item.icon}
              <span className={`ml-3 ${!sidebarOpen && 'hidden'}`}>{item.label}</span>
            </button>
          ))}
        </nav>

        <div className="p-4 border-t border-indigo-600">
          <button className="w-full flex items-center text-gray-300 hover:text-white transition-colors duration-200">
            <LogOut size={20} />
            <span className={`ml-3 ${!sidebarOpen && 'hidden'}`}>Logout</span>
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
<header className="bg-white border-b border-gray-200 shadow-sm">
  <div className="flex items-center justify-between p-4">
    <div className="flex items-center flex-1">
      <div className="relative w-96">
        <input
          type="text"
          placeholder="Ask VRealityAI to analyze or optimize..."
          className="w-full pl-12 pr-12 py-2.5 rounded-lg border border-gray-300 
                   focus:outline-none focus:border-indigo-500 focus:ring-2 
                   focus:ring-indigo-200 transition-all duration-200"
        />
        <Sparkles 
          className="absolute left-3 top-2.5 text-indigo-500" 
          size={20} 
        />
        <Mic 
          className="absolute right-3 top-2.5 text-gray-400 hover:text-indigo-500 cursor-pointer" 
          size={20} 
        />
      </div>
      
    </div>
    
    {/* Rest of your existing header content */}
    <div className="flex items-center gap-6">
      <button className="p-2 hover:bg-gray-100 rounded-lg relative transition-colors duration-200">
        <Bell size={20} className="text-gray-600" />
        <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full ring-2 ring-white"></span>
      </button>
      <div className="flex items-center gap-3">
        <div className="w-10 h-10 rounded-full bg-gradient-to-r from-indigo-500 to-purple-500 flex items-center justify-center text-white font-medium">
          JD
        </div>
        <div className="flex flex-col">
          <span className="font-medium text-gray-700">John Developer</span>
          <span className="text-sm text-gray-500">Admin</span>
        </div>
      </div>
    </div>
  </div>
</header>

        {/* Main Dashboard Content */}
        <main className="flex-1 overflow-y-auto p-6">
          {/* Welcome Section */}
          <div className="bg-white rounded-xl shadow-sm mb-6 overflow-hidden">
            <div className="p-6 bg-gradient-to-r from-indigo-500 to-purple-500 text-white">
              <div className="flex justify-between items-start">
                <div>
                  <h2 className="text-2xl font-bold">Good {timeOfDay}, John! 👋</h2>
                  <p className="mt-1 text-indigo-100">Here's what's happening with your game today.</p>
                </div>
                <div className="text-right">
                  <p className="text-sm text-indigo-200">Last updated</p>
                  <p className="text-sm font-medium text-white">{new Date().toLocaleTimeString()}</p>
                </div>
              </div>
            </div>
          </div>

          {/* Key Performance Indicators */}
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
  {[
    { 
      label: 'Active Players',
      value: '12,847',
      change: '+15%',
      trend: [45, 62, 58, 63, 70, 73, 80],
      icon: <Users className="text-indigo-600" />,
      details: [
        { label: 'Peak Today', value: '15,280' },
        { label: 'PS5 Players', value: '8,542' },
        { label: 'PS4 Players', value: '4,305' },
      ],
      status: 'increasing'
    },
    {
      label: 'Session Analytics',
      value: '45 mins',
      change: '+8%',
      trend: [30, 35, 38, 40, 42, 45, 45],
      icon: <Clock className="text-green-600" />,
      details: [
        { label: 'Peak Hours', value: '18:00-22:00' },
        { label: 'Avg Session (PS5)', value: '52 mins' },
        { label: 'Avg Session (PS4)', value: '38 mins' },
      ],
      status: 'stable'
    },
    {
      label: 'Player Retention',
      value: '68%',
      change: '+12%',
      trend: [50, 55, 58, 62, 65, 67, 68],
      icon: <TrendingUp className="text-blue-600" />,
      details: [
        { label: 'Day 1', value: '92%' },
        { label: 'Day 7', value: '68%' },
        { label: 'Day 30', value: '45%' },
      ],
      status: 'increasing'
    },
    {
      label: 'AI Performance',
      value: '94',
      change: '+5%',
      trend: [82, 85, 88, 90, 92, 93, 94],
      icon: <Sparkles className="text-purple-600" />,
      details: [
        { label: 'Response Time', value: '12ms' },
        { label: 'Accuracy', value: '96%' },
        { label: 'Adaptations/hr', value: '850' },
      ],
      status: 'optimal'
    }
  ].map((metric, index) => (
    <div key={index} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200">
      <div className="flex items-start justify-between mb-4">
        <div>
          <p className="text-gray-500 text-sm font-medium flex items-center">
            {metric.label}
            {metric.status && (
              <span className={`ml-2 px-2 py-0.5 rounded-full text-xs ${
                metric.status === 'increasing' ? 'bg-green-100 text-green-800' :
                metric.status === 'stable' ? 'bg-blue-100 text-blue-800' :
                metric.status === 'optimal' ? 'bg-purple-100 text-purple-800' :
                'bg-yellow-100 text-yellow-800'
              }`}>
                {metric.status}
              </span>
            )}
          </p>
          <div className="mt-2 flex items-baseline">
            <p className="text-3xl font-bold">{metric.value}</p>
            <span className={`ml-2 text-sm ${
              metric.change.startsWith('+') ? 'text-green-500' : 'text-red-500'
            }`}>
              {metric.change}
            </span>
          </div>
        </div>
        <div className="p-2 bg-gray-50 rounded-lg">
          {metric.icon}
        </div>
      </div>

      {/* Sparkline Chart */}
      <div className="h-8 mb-4">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={metric.trend.map((value, i) => ({ value }))}>
            <Line
              type="monotone"
              dataKey="value"
              stroke={
                metric.status === 'increasing' ? '#10B981' :
                metric.status === 'stable' ? '#3B82F6' :
                metric.status === 'optimal' ? '#8B5CF6' :
                '#FBBF24'
              }
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Detailed Metrics */}
      <div className="space-y-2">
        {metric.details.map((detail, i) => (
          <div key={i} className="flex justify-between items-center text-sm">
            <span className="text-gray-500">{detail.label}</span>
            <span className="font-medium text-gray-900">{detail.value}</span>
          </div>
        ))}
      </div>
    </div>
  ))}
</div>
         {/* AI Insights Section */}
    <div className="mb-6">
      <h3 className="text-lg font-medium mb-4 flex items-center">
        <Brain className="mr-2" size={20} />
        AI-Powered Insights
        <span className="ml-2 text-sm text-gray-500">(Updates every 30s)</span>
      </h3>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {currentInsights.map((insight, index) => (
          <InsightCard 
            key={`${insight.title}-${index}`} 
            insight={insight}
          />
        ))}
      </div>
    </div>

{/* Emotional Analytics Section */}
<div className="bg-white rounded-xl shadow-sm p-6 mb-6">
  <h3 className="text-lg font-medium mb-4 flex items-center">
    <Brain className="mr-2 text-indigo-500" size={20} />
    Player Emotional Analytics
  </h3>
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
    {/* Emotional State Distribution */}
    <div className="space-y-4">
      <h4 className="font-medium text-gray-700">Current Emotional States</h4>
      {[
        { emotion: 'Engaged', value: 45, trend: '+5%', color: 'green' },
        { emotion: 'Excited', value: 25, trend: '+2%', color: 'blue' },
        { emotion: 'Frustrated', value: 15, trend: '-3%', color: 'red' },
        { emotion: 'Neutral', value: 15, trend: '-4%', color: 'gray' }
      ].map((emotion, index) => (
        <div key={index} className="relative">
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium text-gray-700">{emotion.emotion}</span>
            <span className={`text-sm font-medium text-${emotion.color}-600`}>
              {emotion.value}% {emotion.trend}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div 
              className={`bg-${emotion.color}-500 h-2 rounded-full`}
              style={{ width: `${emotion.value}%` }}
            />
          </div>
        </div>
      ))}
    </div>

    {/* Emotional Triggers */}
    <div className="space-y-4">
      <h4 className="font-medium text-gray-700">Key Emotional Triggers</h4>
      <div className="space-y-3">
        {[
          {
            trigger: 'Boss Battle Difficulty',
            impact: 'High Excitement & Challenge',
            trend: 'positive',
            details: '85% positive engagement'
          },
          {
            trigger: 'Reward Distribution',
            impact: 'Satisfaction Peaks',
            trend: 'positive',
            details: '92% positive response'
          },
          {
            trigger: 'Tutorial Complexity',
            impact: 'Minor Frustration',
            trend: 'negative',
            details: '23% confusion rate'
          }
        ].map((trigger, index) => (
          <div key={index} className="border rounded-lg p-3">
            <div className="flex justify-between items-start mb-1">
              <span className="font-medium text-gray-800">{trigger.trigger}</span>
              <span className={`px-2 py-1 rounded-full text-xs ${
                trigger.trend === 'positive' ? 'bg-green-100 text-green-800' : 
                'bg-red-100 text-red-800'
              }`}>
                {trigger.impact}
              </span>
            </div>
            <p className="text-sm text-gray-600">{trigger.details}</p>
          </div>
        ))}
      </div>
    </div>
  </div>

  {/* Real-time Emotional Insights */}
  <div className="mt-6 border-t pt-4">
    <h4 className="font-medium text-gray-700 mb-3">Real-time Emotional Insights</h4>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {[
        {
          title: 'Peak Engagement Times',
          value: '8PM - 11PM',
          detail: 'Highest emotional intensity during evening raids'
        },
        {
          title: 'Social Impact',
          value: '+27%',
          detail: 'Increased positive emotions during group activities'
        },
        {
          title: 'Experience Flow',
          value: '92%',
          detail: 'Players in optimal challenge-skill balance'
        }
      ].map((insight, index) => (
        <div key={index} className="bg-gray-50 rounded-lg p-4">
          <h5 className="text-sm text-gray-600">{insight.title}</h5>
          <div className="text-xl font-bold text-gray-900 mt-1">{insight.value}</div>
          <p className="text-sm text-gray-500 mt-1">{insight.detail}</p>
        </div>
      ))}
    </div>
  </div>
</div>



          {/* Charts Section */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
            {/* Player Retention Chart */}
            <div className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow duration-200">
              <h3 className="text-lg font-medium mb-4 flex items-center">
                <TrendingUp className="mr-2 text-indigo-500" size={20} />
                Player Retention & Predictions
              </h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={retentionData}>
                    <XAxis 
                      dataKey="day" 
                      stroke="#94a3b8"
                      fontSize={12}
                    />
                    <YAxis 
                      stroke="#94a3b8"
                      fontSize={12}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Line 
                      type="monotone" 
                      dataKey="players" 
                      stroke="#4f46e5"
                      strokeWidth={2}
                      dot={{ fill: '#4f46e5', strokeWidth: 2 }}
                      name="Actual"
                    />
                    <Line 
                      type="monotone" 
                      dataKey="predicted" 
                      stroke="#818cf8"
                      strokeDasharray="5 5"
                      strokeWidth={2}
                      dot={false}
                      name="Predicted"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* NPC Interactions Chart */}
            <div className="bg-white rounded-xl shadow-sm p-6 hover:shadow-md transition-shadow duration-200">
              <h3 className="text-lg font-medium mb-4 flex items-center">
                <Users className="mr-2 text-indigo-500" size={20} />
                NPC Interactions
              </h3>
              <div className="h-64">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={npcInteractions}>
                    <XAxis 
                      dataKey="time" 
                      stroke="#94a3b8"
                      fontSize={12}
                    />
                    <YAxis 
                      stroke="#94a3b8"
                      fontSize={12}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <defs>
                      <linearGradient id="colorInteractions" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#818cf8" stopOpacity={0.3}/>
                        <stop offset="95%" stopColor="#818cf8" stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <Area
                      type="monotone"
                      dataKey="count"
                      stroke="#6366f1"
                      fill="url(#colorInteractions)"
                      name="Interactions"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

        {/* Recent AI Adaptations */}
        <div className="mt-6">
            <h3 className="text-lg font-medium mb-4 flex items-center">
              <Zap className="mr-2" size={20} />
              Recent AI Adaptations
            </h3>
            <div className="bg-white rounded-lg shadow-sm">
              <div className="p-6 space-y-4">
                {[
                  {
                    title: "Dynamic Difficulty Adjustment",
                    description: "Difficulty reduced by 15% for new players in Zone A-3",
                    timestamp: "10 minutes ago",
                    impact: "Improved new player retention",
                    status: "active"
                  },
                  {
                    title: "NPC Behavior Update",
                    description: "Enhanced dialogue options for merchant NPCs based on player preferences",
                    timestamp: "1 hour ago",
                    impact: "28% increase in player engagement",
                    status: "monitoring"
                  },
                  {
                    title: "Resource Distribution",
                    description: "Adjusted spawn rates in under-utilized areas",
                    timestamp: "3 hours ago",
                    impact: "Better map utilization",
                    status: "completed"
                  }
                ].map((adaptation, index) => (
                  <div key={index} className="flex items-start p-4 border rounded-lg">
                    <div className={`mt-1.5 w-2 h-2 rounded-full mr-4 ${
                      adaptation.status === 'active' ? 'bg-green-500' :
                      adaptation.status === 'monitoring' ? 'bg-yellow-500' :
                      'bg-blue-500'
                    }`} />
                    <div className="flex-1">
                      <div className="flex justify-between items-start">
                        <h4 className="font-medium text-gray-900">{adaptation.title}</h4>
                        <span className="text-sm text-gray-500">{adaptation.timestamp}</span>
                      </div>
                      <p className="text-gray-600 mt-1">{adaptation.description}</p>
                      <div className="flex items-center mt-2 text-sm">
                        <Target className="w-4 h-4 mr-1 text-gray-500" />
                        <span className="text-gray-600">{adaptation.impact}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

{/* PSVR2 Performance Metrics */}
<div className="bg-white rounded-xl shadow-sm p-6 mt-6">
  <h3 className="text-lg font-medium mb-4 flex items-center">
    <Gamepad className="mr-2 text-indigo-500" size={20} />
    PSVR2 Performance Metrics
  </h3>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    {[
      {
        label: 'Motion Tracking',
        value: '98%',
        detail: 'Accuracy Rate',
        trend: '+3%',
        status: 'optimal'
      },
      {
        label: 'Frame Rate',
        value: '120fps',
        detail: 'Average',
        trend: 'stable',
        status: 'stable'
      },
      {
        label: 'Latency',
        value: '12ms',
        detail: 'Response Time',
        trend: '-2ms',
        status: 'optimal'
      }
    ].map((metric, index) => (
      <div key={index} className="border rounded-lg p-4">
        <div className="flex justify-between items-start mb-2">
          <span className="text-sm text-gray-500">{metric.label}</span>
          <span className={`px-2 py-1 rounded-full text-xs ${
            metric.status === 'optimal' ? 'bg-green-100 text-green-800' :
            'bg-blue-100 text-blue-800'
          }`}>
            {metric.status}
          </span>
        </div>
        <div className="text-xl font-bold mb-1">{metric.value}</div>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">{metric.detail}</span>
          <span className="text-sm text-green-500">{metric.trend}</span>
        </div>
      </div>
    ))}
  </div>
</div>

{/* Engine Integration Status */}
<div className="bg-white rounded-xl shadow-sm p-6 mt-6">
  <h3 className="text-lg font-medium mb-4 flex items-center">
    <Settings className="mr-2 text-indigo-500" size={20} />
    Engine Integration Status
  </h3>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
    {[
      {
        engine: 'Unreal Engine 5',
        status: 'Connected',
        lastSync: '2 mins ago',
        performance: '99.9%',
        color: 'green'
      },
      {
        engine: 'Unity',
        status: 'Connected',
        lastSync: '1 min ago',
        performance: '99.7%',
        color: 'blue'
      },
      {
        engine: 'Custom Engine',
        status: 'Connected via SDK',
        lastSync: '5 mins ago',
        performance: '99.5%',
        color: 'purple'
      }
    ].map((engine, index) => (
      <div key={index} className={`border-l-4 border-${engine.color}-500 rounded-lg p-4 bg-gradient-to-r from-${engine.color}-50 to-white`}>
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-medium">{engine.engine}</h4>
          <span className={`px-2 py-1 rounded-full text-xs bg-${engine.color}-100 text-${engine.color}-800`}>
            {engine.status}
          </span>
        </div>
        <div className="space-y-2 text-sm">
          <div className="flex justify-between">
            <span className="text-gray-500">Last Sync</span>
            <span>{engine.lastSync}</span>
          </div>
          <div className="flex justify-between">
            <span className="text-gray-500">Performance</span>
            <span className="text-green-500">{engine.performance}</span>
          </div>
        </div>
      </div>
    ))}
  </div>
</div>

{/* Middleware Performance */}
<div className="bg-white rounded-xl shadow-sm p-6 mt-6">
  <h3 className="text-lg font-medium mb-4 flex items-center">
    <Brain className="mr-2 text-indigo-500" size={20} />
    Middleware Performance
  </h3>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div className="space-y-4">
      <div className="p-4 border rounded-lg">
        <h4 className="font-medium mb-3">API Performance</h4>
        <div className="space-y-2">
          {[
            { label: 'Response Time', value: '12ms', trend: '-2ms' },
            { label: 'Request Success Rate', value: '99.99%', trend: '+0.01%' },
            { label: 'Data Processing', value: '850k/sec', trend: '+50k' }
          ].map((metric, i) => (
            <div key={i} className="flex items-center justify-between text-sm">
              <span className="text-gray-500">{metric.label}</span>
              <div>
                <span className="font-medium">{metric.value}</span>
                <span className="ml-2 text-green-500 text-xs">{metric.trend}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    <div className="space-y-4">
      <div className="p-4 border rounded-lg">
        <h4 className="font-medium mb-3">System Resources</h4>
        <div className="space-y-2">
          {[
            { label: 'Memory Usage', value: '1.2GB', trend: 'stable' },
            { label: 'CPU Load', value: '2.5%', trend: 'optimal' },
            { label: 'Network I/O', value: '450MB/s', trend: 'normal' }
          ].map((metric, i) => (
            <div key={i} className="flex items-center justify-between text-sm">
              <span className="text-gray-500">{metric.label}</span>
              <div>
                <span className="font-medium">{metric.value}</span>
                <span className={`ml-2 px-2 py-0.5 rounded-full text-xs ${
                  metric.trend === 'optimal' ? 'bg-green-100 text-green-800' :
                  metric.trend === 'stable' ? 'bg-blue-100 text-blue-800' :
                  'bg-yellow-100 text-yellow-800'
                }`}>{metric.trend}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</div>

{/* Platform Optimization */}
<div className="bg-white rounded-xl shadow-sm p-6 mt-6">
  <h3 className="text-lg font-medium mb-4 flex items-center">
    <Target className="mr-2 text-indigo-500" size={20} />
    Platform Optimization Recommendations
  </h3>
  <div className="space-y-4">
    {[
      {
        platform: 'PS5',
        recommendations: [
          { type: 'Performance', message: 'Optimize texture streaming for faster load times', priority: 'high' },
          { type: 'Memory', message: 'Implement dynamic resource allocation', priority: 'medium' }
        ]
      },
      {
        platform: 'PS4',
        recommendations: [
          { type: 'Graphics', message: 'Adjust LOD settings for better performance', priority: 'high' },
          { type: 'CPU', message: 'Optimize AI processing routines', priority: 'medium' }
        ]
      },
      {
        platform: 'PSVR2',
        recommendations: [
          { type: 'Latency', message: 'Reduce motion-to-photon latency', priority: 'critical' },
          { type: 'Tracking', message: 'Enhance controller prediction algorithms', priority: 'high' }
        ]
      }
    ].map((platform, index) => (
      <div key={index} className="border rounded-lg p-4">
        <h4 className="font-medium mb-3">{platform.platform} Optimizations</h4>
        <div className="space-y-2">
          {platform.recommendations.map((rec, i) => (
            <div key={i} className="flex items-start space-x-3">
              <span className={`mt-1 w-2 h-2 rounded-full flex-shrink-0 ${
                rec.priority === 'critical' ? 'bg-red-500' :
                rec.priority === 'high' ? 'bg-yellow-500' :
                'bg-blue-500'
              }`} />
              <div>
                <span className="font-medium text-sm">{rec.type}:</span>
                <span className="text-sm text-gray-600 ml-1">{rec.message}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
</div>

          {/* Quick Actions */}
          <div className="bg-white rounded-xl shadow-sm p-6 mt-6">
            <h3 className="text-lg font-medium mb-4 flex items-center">
              <Zap className="mr-2 text-indigo-500" size={20} />
              Quick Actions
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {[
                {
                  icon: <MessageSquare size={24} />,
                  label: "Send Notification",
                  description: "Broadcast message to players",
                  color: "indigo"
                },
                {
                  icon: <Brain size={24} />,
                  label: "Update AI Parameters",
                  description: "Modify game adaptation settings",
                  color: "purple"
                },
                {
                  icon: <AlertCircle size={24} />,
                  label: "View Alerts",
                  description: "Check system notifications",
                  color: "yellow"
                },
                {
                  icon: <Target size={24} />,
                  label: "Set Goals",
                  description: "Update engagement targets",
                  color: "green"
                }
              ].map((action, index) => (
                <button
                  key={index}
                  className={`flex flex-col items-center p-6 rounded-xl border-2 border-${action.color}-100 
                    hover:bg-${action.color}-50 hover:border-${action.color}-200 
                    transition-all duration-200`}
                >
                  <div className={`p-3 bg-${action.color}-100 rounded-lg mb-3 text-${action.color}-600`}>
                    {action.icon}
                  </div>
                  <span className="font-medium text-gray-900 mb-1">{action.label}</span>
                  <span className="text-sm text-gray-500 text-center">{action.description}</span>
                </button>
              ))}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;